import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'

import Link from '../utils/link'

import Seo from '../components/seo'
import Search from '../assets/images/search-blue.svg'
import DownArrow from '../assets/images/down_arrow-white.svg'
import Pdf from '../assets/images/pdf.svg'

class IndexPage extends Component {

  state = {
    manuals: this.props.data.allWpManual.nodes,
    filter: '',
  }

  filterPosts = (filter, items) => {
    let manuals = this.props.data.allWpManual.nodes

    if (filter !== '') {
      manuals = items
    }

    this.setState({
      manuals,
      filter
    })
  }

  search = (event) => {
    let { value } = event.target
    value = value.toLowerCase()
    let { filter } = this.state
    let manuals = this.props.data.allWpManual.nodes
    if (filter !== '') {
      let category = this.props.data.allWpCategory.nodes.find(el => el.name === filter)
      manuals = category.manuals.nodes
    }
    manuals = manuals.filter((el) => (el.title.toLowerCase().indexOf(value) !== -1 || el.acf.modelNo.toLowerCase().indexOf(value) !== -1))
    this.setState({ manuals })
  }

  render() {

    let { manuals, filter } = this.state

    const categories = this.props.data.allWpCategory.nodes

    return (
      <>
      	<Seo title='Product Manuals - Lenoxx' bodyClass='product-manuals' description='' />
        <section className='manuals-header'>
        	<div className='manuals-header__inner'>
        		<h1>Find a Lenoxx Product Manual</h1>
        		<form className="manuals-header__search" onSubmit={(e) => e.preventDefault()}>
              <input type="search" name="s" placeholder="Search by Model No., Product Name, or keyword" onChange={this.search} />
              <button type="submit">
                <img src={Search} alt="Search" />
              </button>
            </form>
            <p>or browse via our directory</p>
        	</div>
        </section>
        <section className='manuals-categories'>
        	<div className='manuals-categories__inner'>
        		<h4 className='manuals-categories__mobile-select'>Select Category <img src={DownArrow} alt='Select category' /></h4>
        		<ul>
              { categories?.slice(0, 10).map((el, i) => (
                <li
                  key={i}
                  className={el.name === filter ? 'active' : ''}
                  onClick={() => this.filterPosts(el.name, el.manuals.nodes)}>
                  { el.name }
                </li>
              )) }
        		</ul>
        	</div>
        </section>
        <section className='breadcrumbs'>
        	<div className='breadcrumbs__inner'>
        		<ul>
        			<li>Home</li>
        			<li>Product Manuals</li>
        		</ul>
        	</div>
        </section>
        <section className='manuals'>
        	<div className='manuals__inner'>
        		<div className='manuals__titles'>
        			<h4>Model No.</h4>
        			<h4>Product Name</h4>
        			<h4>Available Downloads</h4>
        		</div>
            <div className='manuals__items'>
            { manuals?.length > 0 ? manuals.map((el, i) => (
              <div className='manuals__item' key={i}>
                <div className='manuals__model'>
                  <h4>Model No.</h4>
                  <span>{ el.acf.modelNo }</span>
                </div>
                <div className='manuals__product'>
                  <h4>Product Name</h4>
                  <span>{ el.title }</span>
                </div>
                <div className='manuals__downloads'>
                  <h4>Available Downloads</h4>
                  <ul>
                    { el.acf.downloads?.map((item, j) => (
                      <li key={i}>
                        <a href={item.file?.localFile?.publicURL} download>
                          <img src={Pdf} alt={item.name} />
                          <span>{ item.name } — { el.title }</span>
                        </a>
                      </li>
                    )) }
                  </ul>
                </div>
              </div>
            )) : <div className='manuals__item'>No items found.</div>}
            </div>
        	</div>
        </section>
      </>
    )
  }
}

export const query = graphql`
  query {
    allWpManual {
      nodes {
        title
        id
        title
        slug
        date
        acf {
					downloads {
					  name
            file {
							localFile {
								publicURL
              }
            }
          }
          modelNo
        }
      }
    }
    allWpCategory {
      nodes {
        manuals {
          nodes {
            title
            id
            title
            slug
            date
            acf {
              downloads {
                name
                file {
                  localFile {
                    publicURL
                  }
                }
              }
              modelNo
            }
          }
        }
        name
        slug
      }
    }
  }
`

export default IndexPage
